var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.cabecalhoTabela,"dense":"","items":_vm.contasCorrentes,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"hide-default-footer":"","disable-pagination":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.tipo_orcamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipoOrcamento)+" ")]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.descricaoLinha)+" ")]}},{key:"item.nome_conta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomeConta)+" ")]}},{key:"item.tipo_investimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipoInvestimento)+" ")]}},{key:"item.saldo_atual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mascaraPercentual ? _vm.getPercent(item.saldo) : _vm.getMoney(item.saldo))+" ")]}},{key:"item.saldo_ajustado",fn:function(ref){
var item = ref.item;
return [_c('conta-corrente-tabela-saldo-ajustado',{attrs:{"id":item.idContaCorrente,"mascara-percentual":_vm.mascaraPercentual}})]}},{key:"item.vlr_reservado",fn:function(ref){
var item = ref.item;
return [(!_vm.mascaraPercentual)?_c('input-money',{attrs:{"dense":"","disabled":_vm.somenteLeitura,"rules":[_vm.rules.maximoPadrao]},on:{"input":function($event){return _vm.$emit('ContaCorrenteTabela_atualizaSaldoAjustado', item)},"InputMoney_valorAlterado":function (valor) { return _vm.atualizaSaldoAjustado(valor, item); }},model:{value:(item.valor),callback:function ($$v) {_vm.$set(item, "valor", $$v)},expression:"item.valor"}}):_c('input-decimal',{attrs:{"suffix":"%","type":"Number","min":"0","dense":"","disabled":_vm.somenteLeitura,"rules":[_vm.rules.maximoPadrao]},on:{"input":function($event){return _vm.$emit('ContaCorrenteTabela_atualizaSaldoAjustado', item)},"InputDecimal_valorAlterado":function (valor) { return _vm.atualizaSaldoAjustado(valor, item); }},model:{value:(item.valor),callback:function ($$v) {_vm.$set(item, "valor", $$v)},expression:"item.valor"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }