<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="reserva.dtaInicioFormatada"
          :label="$t('label.data_inicio')"
          prepend-icon="event"
          disabled>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="reserva.dtaFimFormatada"
          :label="$t('label.data_fim')"
          prepend-icon="event"
          disabled>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <input-decimal
            v-if="this.mascaraPercentual"
            :label="$t('label.valor')"
            v-model="reserva.valor"
            :rules="[rules.maximoPadrao]"
            @InputDecimal_valorAlterado="valor => valorAlterado(valor, reserva.valor)"
            suffix="%"
            type="Number"
            min="0"
            :disabled="true"/>
        <input-money
            v-else
            :label="$tc('label.valor', 1)"
            v-model="reserva.valor"
            :disabled="somenteLeitura||verbaRateio"
            :rules="[rules.maximoPadrao]"
            @InputMoney_valorAlterado="valor => valorAlterado(valor, reserva.valor)"/>
      </v-col>
      <v-col cols="12" md="3">
        <input-decimal
            v-if="this.mascaraPercentual"
            :label="$t('label.pendente_reserva')"
            v-model="reserva.pendente"
            v-bind:class="{
              'PlanejamentoAcaoFormContaCorrente__valor-negativo': (reserva.pendente < 0)
            }"
            suffix="%"
            type="Number"
            min="0"
            :disabled="true"/>
        <input-money
            v-else
            :label="$tc('label.pendente_reserva', 1)"
            v-model="reserva.pendente"
            v-bind:class="{
              'PlanejamentoAcaoFormContaCorrente__valor-negativo': (reserva.pendente < 0)
            }"
            disabled/>
      </v-col>
    </v-row>
    <v-row>
      <conta-corrente-tabela style="width: 100%"
        :contas-correntes="reserva.contas"
        :somente-leitura="somenteLeitura"
        :exibir-saldo="exibirSaldo"
        :verba-rateio="verbaRateio"
        :mascara-percentual="mascaraPercentual"
        @ContaCorrenteTabela_atualizaSaldoAjustado="atualizaSaldoAjustado">
      </conta-corrente-tabela>
    </v-row>
  </v-container>
</template>

<script>
import InputMoney from '../../../../shared-components/inputs/InputMoney';
import InputDecimal from '../../../../shared-components/inputs/InputDecimal';
import Reserva from './Reserva';
import ContaCorrenteTabela from './ContaCorrenteTabela';

export default {
  name: 'PlanejamentoAcaoReservaApuracao',
  components: {
    ContaCorrenteTabela,
    InputMoney,
    InputDecimal,
  },
  props: {
    reserva: {
      type: Reserva,
      required: true,
    },
    somenteLeitura: Boolean,
    verbaRateio: Boolean,
    contasCorrentes: {
      type: Array,
      default: () => ([]),
    },
    exibirSaldo: {
      type: Boolean,
      default: true,
    },
    mascaraPercentual: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        maximoPadrao: (value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo'),
      },
    };
  },
  watch: {
    contasCorrentes(values) {
      this.reserva.filtrarEPreencherConta(values);
    },
  },
  methods: {
    valorAlterado(valor, valorAnterior) {
      this.$emit('PlanejamentoAcaoReservaApuracao_valorAlterado', valor - valorAnterior);
    },
    atualizaSaldoAjustado() {
      this.reserva.ajustarPendente();
      this.$emit('PlanejamentoAcaoReservaApuracao_reservaAlterada');
    },
  },
};
</script>
