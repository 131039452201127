<template>
  <span
    v-bind:class="{
        'PlanejamentoAcaoFormContaCorrente__valor-negativo': (saldo < 0)
      }">
     {{ mascaraPercentual ? getPercent(saldo) : getMoney(saldo) }}
  </span>
</template>
<script>
import { mapGetters } from 'vuex';
import { getMoney, getPercent } from '../../../../common/functions/helpers';

export default {
  name: 'ContaCorrenteTabelaSaldoAjustado',
  props: {
    id: {
      type: Number,
      required: true,
    },
    mascaraPercentual: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('acaoSaldoContaCorrente', [
      'getContasCorrentes',
    ]),
    saldo() {
      return this.getContasCorrentes[this.id];
    },
  },
  methods: {
    getMoney,
    getPercent,
  },
};
</script>
