<style>
.PlanejamentoAcaoFormContaCorrente .title-float {
  font-weight: 400;
  font-size: 20px;
}

.PlanejamentoAcaoFormContaCorrente__valor-negativo,
.PlanejamentoAcaoFormContaCorrente__valor-negativo input.v-money {
  color: #ff0000ba;
}
</style>
<template>
  <div class="PlanejamentoAcaoFormContaCorrente">
    <planejamento-acao-form-resumo
        :exibe-fornecedor-pagador="exibeFornecedorPagador"
        :exibe-cliente-pagador="exibeClientePagador"
        :objeto-passo-geral="objetoPassoGeral"
        :ordenacao-formulario="ordenacaoFormulario"
        :somenteLeitura="somenteLeitura"
        ref="resumo">
      <div slot="titulo-acao">
        <slot name="titulo-acao"/>
      </div>
    </planejamento-acao-form-resumo>

    <div class="mt-2 mb-2 title-float accent--text">
      {{ $tc('label.conta_corrente', 2) }}
    </div>

    <v-card>
      <v-container fluid grid-list-md>
        <v-row>
          <v-col cols="12" md="3">
            <v-select
                id="planejamento-cc-autocomplete-tipoverba"
                :label="`${$t('label.tipo_verba')} *`"
                :items="tiposVerbas"
                item-text="nome"
                item-value="id"
                :append-icon="mostrarIconeVerbaBloqueada()"
                v-model="formulario.idTipoVerba"
                :disabled="somenteLeitura || tipoSelecionado"
                @change="tipoVerbaSelecionado(formulario.idTipoVerba)"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
                id="planejamento-cc-autocomplete-periodo"
                class="custom-autocomplete"
                multiple
                v-model="formulario.periodos"
                return-object
                item-text="nome"
                item-value="id"
                :items="periodosContas"
                :label="`${$tc('label.periodo', 1)} *`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :clearable="true"
                :disabled="somenteLeitura || !formulario.idTipoVerba"
                :placeholder="(somenteLeitura
                 || !formulario.idTipoVerba)
                  ? null
                   : $tc('message.digite_para_pesquisar', 1)">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-center">
            <v-btn @click="buscarContasCorrentes(true)"
                   :disabled="!formulario.periodos.length"
                   v-if="!somenteLeitura">
              {{ $t('label.buscar') }}
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="mostrarFraseVerbaBloqueada && verbaSelecionada.indBloqueioTemp && !novaAcao && acaoEdicao.passoGeral.status === 'EM_CADASTRO'"
             style="color:#ff0000ba">
          {{
            `Não é possível enviar esta ação para aprovação, pois está bloqueado o uso da verba ${verbaSelecionada.nome} no período de ${formataData(eventoCalendario.dataInicio)} até ${formataData(eventoCalendario.dataFim)}. Por favor aguarde o final do bloqueio para solicitar a aprovação ou altere o tipo de verba desta ação para prosseguir.`
          }}
        </div>
      </v-container>
    </v-card>

    <div v-if="carregando" class="text-xs-center mt-5 accent--text">
      <v-progress-circular :indeterminate="true"/>
    </div>

    <planejamento-acao-form-conta-corrente-vinculos
        ref="vinculosCampos"
        :configuracao="configuracao"
        :objeto-passo-geral="objetoPassoGeral"
        @PLANEJAMENTO_ACAO_VINCULOS_TOGGLE="refazBuscaContasCorrentes"
        class="mt-3"
        :somente-leitura="somenteLeitura"/>

    <v-card class="mt-3">
      <v-card-title>
        {{ this.$tc('label.distribuicao_valor', 2) }}
      </v-card-title>
      <v-container fluid grid-list-md>
        <v-row v-if="!formulario.indRateioDivisao">
          <v-col cols="12" md="3">
            <input-decimal
                v-if="mascaraPercent"
                :label="$tc('label.valor', 1)"
                v-model="valorDaAcao"
                suffix="%"
                type="Number"
                min="0"
                :disabled="true"/>
            <input-money
                v-else
                :label="$tc('label.valor', 1)"
                v-model="valorDaAcao"
                :disabled="true"/>
          </v-col>
          <v-col cols="12" md="3" v-if="reservas.length > 1">
            <input-decimal
                v-if="mascaraPercent"
                :label="$tc('label.valor_total', 1)"
                v-model="valorTotal"
                suffix="%"
                type="Number"
                min="0"
                :disabled="true"/>
            <input-money
                v-else
                :label="$tc('label.valor_total', 1)"
                v-model="valorTotal"
                :disabled="true"/>
          </v-col>
          <v-col cols="12" md="3">
            <input-decimal
                v-if="mascaraPercent"
                :label="$t('label.pendente_reserva')"
                v-model="formulario.pendente"
                v-bind:class="{
                    'PlanejamentoAcaoFormContaCorrente__valor-negativo': (formulario.pendente < 0)
                  }"
                suffix="%"
                type="Number"
                min="0"
                :disabled="true"/>
            <input-money
                v-else
                :label="$t('label.pendente_reserva')"
                v-model="formulario.pendente"
                v-bind:class="{
                    'PlanejamentoAcaoFormContaCorrente__valor-negativo': (formulario.pendente < 0)
                  }"
                :disabled="true"/>
          </v-col>
          <v-col cols="12" v-if="reservas.length === 1">
            <conta-corrente-tabela
                :somente-leitura="somenteLeitura"
                :contas-correntes="reservas[0].contas"
                :exibir-saldo="exibirSaldo"
                :mascara-percentual="mascaraPercent"
                @ContaCorrenteTabela_atualizaSaldoAjustado="atualizaSaldoAjustado">
            </conta-corrente-tabela>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="3" v-if="valorPercentualAplicado">
            <input-decimal
                :label="$tc('label.verba_percent_aplicado', 1)"
                v-model="valorPercentualAplicado"
                suffix="%"
                type="Number"
                min="0"
                :disabled="true"/>
          </v-col>
          <v-col cols="12" md="3">
            <input-decimal
                v-if="mascaraPercent || verbaRateioPercentual"
                :label="$tc('label.verba', 1)"
                v-model="valorDaAcao"
                suffix="%"
                type="Number"
                min="0"
                :disabled="true"/>
            <input-money
                v-else
                :label="$tc('label.valor', 1)"
                v-model="valorDaAcao"
                :disabled="true"/>
          </v-col>
          <v-col cols="12" md="3" v-if="verbaRateioPercentual">
            <input-money
                :label="$tc('label.valor_monetario', 1)"
                v-model="formulario.valorMonetario"
                :disabled="true"/>
          </v-col>
          <v-col cols="12" md="3" v-if="reservas.length > 1">
            <input-decimal
                v-if="mascaraPercent"
                :label="$tc('label.valor_total', 1)"
                v-model="valorTotal"
                suffix="%"
                type="Number"
                min="0"
                :disabled="true"/>
            <input-money
                v-else
                :label="$tc('label.valor_total', 1)"
                v-model="valorTotal"
                :disabled="true"/>
          </v-col>
          <v-col cols="12" md="3">
            <input-money
                :label="$t('label.pendente_reserva')"
                v-model="formulario.pendente"
                v-bind:class="{
                    'PlanejamentoAcaoFormContaCorrente__valor-negativo': (formulario.pendente < 0)
                  }"
                :disabled="true"/>
          </v-col>
          <v-col cols="12" v-if="reservas.length === 1">
            <conta-corrente-tabela
                :somente-leitura="somenteLeitura"
                :contas-correntes="reservas[0].contas"
                :exibir-saldo="exibirSaldo"
                :verba-rateio="formulario.indRateioDivisao"
                :mascara-percentual="mascaraPercent"
                @ContaCorrenteTabela_atualizaSaldoAjustado="atualizaSaldoAjustado">
            </conta-corrente-tabela>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <planejamento-acao-reservas-apuracao
        v-if="reservas.length > 1"
        class="mt-6"
        :reservas="reservas"
        :contas-correntes="contasCorrentes"
        :somente-leitura="somenteLeitura"
        :exibir-saldo="exibirSaldo"
        :mascara-percentual="mascaraPercent"
        :verba-rateio="formulario.indRateioDivisao"
        @PlanejamentoAcaoReservasApuracao_valorTotalAlterado="valorTotalAlterado"
        @PlanejamentoAcaoReservasApuracao_reservaAlterada="reservaAlterada">
    </planejamento-acao-reservas-apuracao>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import InputMoney from '../../../produto/shared-components/inputs/InputMoney';
import { skipLoading } from '../../../produto/common/functions/loading';
import PlanejamentoAcaoFormResumo from '../../../produto/spa/planejamento-acao/form/PlanejamentoAcaoFormResumo';
import PlanejamentoAcaoFormContaCorrenteVinculos from '../../../produto/spa/planejamento-acao/form/PlanejamentoAcaoFormContaCorrenteVinculos';
import ContaCorrenteTabela from '../../../produto/spa/planejamento-acao/form/conta-corrente/ContaCorrenteTabela';
import PlanejamentoAcaoReservasApuracao from '../../../produto/spa/planejamento-acao/form/conta-corrente/PlanejamentoAcaoReservasApuracao';
import PlanejamentoAcaoFormContaCorrenteApuracao
  from '../../../produto/spa/planejamento-acao/form/conta-corrente/PlanejamentoAcaoFormContaCorrenteApuracao';
import ContaCorrenteRedistribuicaoMixin from '../../../produto/spa/planejamento-acao/form/conta-corrente/ContaCorrenteRedistribuicaoMixin';
import Reserva from '../../../produto/spa/planejamento-acao/form/conta-corrente/Reserva';

import { buscarContasCorrentesElegiveis, obterCalendarioPorMnemonico } from '../../../produto/common/resources/planejamento/planejamento-acao-cadastro';
import AcaoComponenteEvento from '../../../produto/spa/planejamento-acao/form/passos-dinamicos/AcaoComponenteEvento';
import InputDecimal from '../../../produto/shared-components/inputs/InputDecimal';

export default {
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    tipoAcao: {
      type: Object,
      required: true,
    },
  },
  mixins: [PlanejamentoAcaoFormContaCorrenteApuracao, ContaCorrenteRedistribuicaoMixin],
  components: {
    InputDecimal,
    PlanejamentoAcaoReservasApuracao,
    ContaCorrenteTabela,
    PlanejamentoAcaoFormResumo,
    PlanejamentoAcaoFormContaCorrenteVinculos,
    InputMoney,
  },
  computed: {
    ...mapGetters('acaoSaldoContaCorrente', [
      'existeContaSaldoNegativo',
      'contasComSaldoNegativo',
    ]),
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador && this.objetoPassoGeral.focoPagamento === 'CLIENTE';
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador && this.objetoPassoGeral.focoPagamento === 'FORNECEDOR';
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    verbaReservada() {
      return this.acaoEdicao && this.acaoEdicao.passoGeral
          && this.acaoEdicao.passoGeral.status
          && this.acaoEdicao.passoGeral.status !== 'EM_CADASTRO'
          && this.acaoEdicao.passoGeral.status !== 'EM_ANALISE';
    },
    exibirSaldo() {
      return this.acaoEdicao && this.acaoEdicao.passoGeral
          && this.acaoEdicao.passoGeral.status
          && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO';
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      carregando: false,
      mascaraPercent: false,
      verbaRateioPercentual: false,

      formulario: {
        pendente: 0,
        periodos: [],
        periodosPesquisados: [],
        idTipoVerba: null,
        indRateioDivisao: false,
        idCalculoMonetario: null,
      },
      contasCorrentes: [],
      periodosContas: [],
      tiposVerbas: [],

      erros: {
        valorDistribuido: this.$t('errors.conta_corrente.valor_nao_distribuido'),
        contaSemSaldo: this.$t('errors.conta_corrente.sem_saldo'),
        tipoVerbaBloqueado: this.$t('errors.conta_corrente.verba_bloqueada'),
        campoTipoVerbaObrigatorio: this.$t('errors.conta_corrente.campo_tipo_verba_obrigatorio'),
        campoPeriodosObrigatorio: this.$t('errors.conta_corrente.campo_periodos_obrigatorio'),
        holdingBloqueado: (dto) => `Não é possível enviar esta ação para aprovação, pois está bloqueado o uso da verba ${dto.verba} para o ${this.$t('label.holding')} ${dto.nome_holding}.
        Para desbloquear o uso da verba para este ${this.$t('label.holding')} é necessário que as comprovações pendentes há mais de ${dto.prazo} dias sejam realizadas.`,
        reservaContaUnica: this.$t('errors.conta_corrente.reserva_conta_unica'),
      },
      valorDaAcao: 0,
      valorPercentualAplicado: 0,
      valorTotal: 0,
      valorReservado: 0,
      verbaSelecionada: {
        indRateioDivisao: false,
      },
      eventoCalendario: {},
      mostrarFraseVerbaBloqueada: false,
      tipoDeVerbaBloqueadoParaHolding: false,
      dtoHoldingEstaBloqueada: {},
      tipoSelecionado: false,
    };
  },
  methods: {
    ...mapActions('acaoSaldoContaCorrente', [
      'registrarContas',
      'setContas',
    ]),
    emitirValorContaAlterado() {
      const evento = new AcaoComponenteEvento('valorContaAlterado');
      this.$emit('PlanejamentoAcaoFormContaCorrente__evento', evento);
    },
    emitirAlteradoContas() {
      const evento = new AcaoComponenteEvento('alteradoContas');
      this.$emit('PlanejamentoAcaoFormContaCorrente__evento', evento);
    },
    onEvent(evento) {
      if (evento.ehEvento('campoProdutoAlterado')
          || evento.ehEvento('periodoApuracaoAlterado')) {
        this.informaAlteracaoContas();
      } else if (evento.ehEvento('campoValorAlterado')) {
        this.informaAlteracaoContasValor();
      } else if (evento.ehEvento('antesIrProximo')) {
        this.validarAntesIrProximo(evento);
      }
    },
    validaSalvar(isSolicitandoAprovacao = false) {
      return this.validacaoCustomizada(isSolicitandoAprovacao);
    },
    preencherObjetoFormatado(acao) {
      acao.passoContas = this.getObjetoFormatado();
    },
    getObjetoPasso() {
      const idsPeriodos = (this.formulario.periodosPesquisados || []).map((p) => p.id);
      const { idTipoVerba } = this.formulario;

      return {
        reservas: this.reservas,
        valorTotal: this.valorTotal,
        valorPendente: this.formulario.pendente,
        idsPeriodos,
        idTipoVerba,
      };
    },
    valorTotalAlterado(valorTotal) {
      this.formulario.pendente = this.objetoPassoGeral.valor;
      this.reservas[0].contas.forEach((conta) => {
        this.formulario.pendente -= conta.valor;
      });
      this.valorTotal = valorTotal;
      if (this.formulario.indRateioDivisao && this.verbaRateioPercentual) {
        this.formulario.pendente = (this.reservas.length > 1
          ? this.formulario.valorMonetario * this.reservas.length
          : this.formulario.valorMonetario) - this.valorReservado;
      } else {
        this.formulario.pendente = (this.reservas.length > 1
          ? this.valorTotal
          : this.valorDaAcao) - this.valorReservado;
      }
    },
    reservaAlterada(valorReservado) {
      let resultValidacao = true;
      this.reservas[0].contas.forEach((conta) => {
        if (conta.valor > conta.saldo) {
          resultValidacao = false;
        }
      });
      if (!resultValidacao) {
        this.$root.$emit('disableBtnAcao', true);
      } else {
        this.$root.$emit('disableBtnAcao', false);
      }
      this.valorReservado = valorReservado;
      if (this.formulario.indRateioDivisao && this.verbaRateioPercentual) {
        this.formulario.pendente = (this.reservas.length > 1
          ? this.formulario.valorMonetario * this.reservas.length
          : this.formulario.valorMonetario) - this.valorReservado;
      } else {
        this.formulario.pendente = (this.reservas.length > 1
          ? this.valorTotal
          : this.valorDaAcao) - this.valorReservado;
      }
    },
    abrePassoContaCorrente() {
      this.$refs.resumo.montaResumo();
      setTimeout(() => {
        if (!this.reservas.length) {
          this.montarReservas(this.atualizaPendenteReserva);
        }
      });
    },
    redistribuirContas() {
      this.tipoSelecionado = false;
      this.contasCorrentes = [];
      this.registrarContas([]);
      this.reservas = [];
      this.formulario = {
        pendente: 0,
        periodos: [],
        idTipoVerba: null,
      };
      this.valorReservado = 0;
      if (this.tiposVerbas.length === 1) {
        this.formulario.idTipoVerba = this.tiposVerbas[0].id;
        this.buscarPeriodos();
      } else {
        this.tiposVerbas.forEach((tipo) => {
          if (tipo.indVerbaPercentual && this.objetoPassoComportamento.formaBonificacao === 'PERCENTUAL') {
            this.formulario.idTipoVerba = tipo.id;
            this.tipoVerbaSelecionado(tipo.id);
            this.tipoSelecionado = true;
          } else if (!tipo.indVerbaPercentual && this.objetoPassoComportamento.formaBonificacao === 'FIXO') {
            this.formulario.idTipoVerba = tipo.id;
            this.tipoVerbaSelecionado(tipo.id);
            this.tipoSelecionado = true;
          }
        });
      }
    },
    alterarValor() {
      this.valorDaAcao = this.objetoPassoGeral.valor;
      this.formulario.pendente = (this.reservas.length > 1 ? this.valorTotal : this.valorDaAcao)
          - this.valorReservado;
    },
    validacaoCustomizada(isSolicitandoAprovacao) {
      if (!isSolicitandoAprovacao) {
        return true;
      }

      const valorDistribuido = this.reservas
        .map((r) => r.valorDistribuido || 0)
        .reduce((p, a) => p + a, 0);

      let naoTemValorPendente = false;

      if (this.reservas.length === 1) {
        naoTemValorPendente = this.formulario.pendente === 0;
      } else {
        naoTemValorPendente = this.reservas
          .map((r) => r.pendente || 0)
          .reduce((p, a) => p + a, 0) === 0;
      }

      if (this.configuracao.indHabilitaContaCorrenteUnica && this.verificaReservaVariasContas()) {
        const msgErro = this.erros.reservaContaUnica;
        this.$toast(msgErro);
        return false;
      }

      const contasNegativos = this.validarValorNegativo();
      const valido = valorDistribuido !== 0
          && naoTemValorPendente && !contasNegativos;

      if (!valido) {
        const msgErro = contasNegativos
          ? this.erros.contaSemSaldo : this.erros.valorDistribuido;
        this.$toast(msgErro);
        return false;
      }

      if (this.verbaSelecionada.indBloqueioTemp && this.mostrarIconeVerbaBloqueada()) {
        const msgErro = this.erros.tipoVerbaBloqueado;
        this.$toast(msgErro);
        return false;
      }

      if (this.tipoDeVerbaBloqueadoParaHolding) {
        const msgErro = this.erros.holdingBloqueado(this.dtoHoldingEstaBloqueada);
        this.$toast(msgErro);
        return false;
      }

      if (!this.formulario.idTipoVerba) {
        const msgErro = this.erros.campoTipoVerbaObrigatorio;
        this.$toast(msgErro);
        return false;
      }

      if (!this.formulario.periodos || !this.formulario.periodos.length) {
        const msgErro = this.erros.campoPeriodosObrigatorio;
        this.$toast(msgErro);
        return false;
      }
      return true;
    },
    validarValorNegativo() {
      if (this.existeContaSaldoNegativo) {
        const idConta = this.contasComSaldoNegativo
          .find((id) => this.verificarContaComValorReserva(id));
        return !!idConta;
      }
      return false;
    },
    verificaReservaVariasContas() {
      const qtdContas = this.reservas.map((value) => value.valorDistribuidoUnicaConta()).reduce((item) => item);
      return qtdContas.length > 1;
    },
    verificarContaComValorReserva(idContaCorrente) {
      const reserva = this.reservas.find((r) => r.contaComValorNaReserva(idContaCorrente));
      return !!reserva;
    },
    atualizaSaldoAjustado() {
      this.reservas[0].ajustarPendente();
      this.reservaAlterada(this.reservas.map((r) => r.valorReservado).reduce((a, b) => a + b, 0));
    },
    atualizaPendenteReserva() {
      const valorTotal = this.reservas
        .map((r) => r.valor)
        .reduce((p, a) => p + a, 0);
      this.valorTotalAlterado(valorTotal);
    },
    refazBuscaContasCorrentes() {
      setTimeout(() => this.buscarContasCorrentes(false));
    },
    buscarContasCorrentes(buscaEmEtapas) {
      if (!this.formulario.periodos.length) {
        return;
      }
      skipLoading();
      this.carregando = true;

      const parametros = this.montaParametrosBusca(buscaEmEtapas);
      if (this.formulario.indRateioDivisao) {
        const parametrosBuscaRateioMonetario = this.montaParametrosBuscaMonetario(buscaEmEtapas);
        Promise.all([
          buscarContasCorrentesElegiveis(parametros, this.$resource),
        ]).then((valores) => {
          if (valores[0].data.contas.length > 0) {
            Promise.all([
              this.planejamentoAcaoResource.buscarValorMonetarioAcaoRateio(parametrosBuscaRateioMonetario),
            ])
              .then((resp) => {
                this.formulario.valorPendenteRateio = resp[0].data[1].valor;
                this.formulario.valorMonetario = resp[0].data[1].valor;
                this.formulario.idCalculoMonetario = resp[0].data[0].id_calculo_monetario;
                if (this.reservas.length > 1) {
                  this.valorTotal = this.formulario.valorPendenteRateio * this.reservas.length;
                  this.reservas.forEach((reserva) => {
                    reserva.pendente = this.formulario.valorMonetario;
                    reserva.valor = this.formulario.valorMonetario;
                  });
                }
                this.trataRespostaBusca(valores[0], buscaEmEtapas);
                this.formulario.periodosPesquisados = [...this.formulario.periodos];
                this.carregando = false;
                setTimeout(() => {
                  window.scrollTo(0, document.body.scrollHeight);
                }, 500);
              });
          } else {
            this.trataRespostaBusca(valores[0], buscaEmEtapas);
            this.formulario.periodosPesquisados = [...this.formulario.periodos];
            this.carregando = false;
            setTimeout(() => {
              window.scrollTo(0, document.body.scrollHeight);
            }, 500);
          }
        }).catch((err) => {
          this.$toast(err.data.error);
          this.carregando = false;
        });
      } else {
        buscarContasCorrentesElegiveis(parametros, this.$resource)
          .then((res) => {
            this.trataRespostaBusca(res, buscaEmEtapas);
            this.formulario.periodosPesquisados = [...this.formulario.periodos];
            this.carregando = false;
            setTimeout(() => {
              window.scrollTo(0, document.body.scrollHeight);
            }, 500);
          })
          .catch((err) => {
            this.$toast(err.data.error);
            this.carregando = false;
          });
      }
    },
    recuperarExtensoesCarteiras() {
      const idsExtensoesCarteira = this.configuracao.extensoesCarteira
        .map((e) => this.objetoPassoGeral[e.label])
        .filter((e) => !!e)
        .map((e) => e.id);
      if (this.configuracao.unidadeNegocioCarteira && this.objetoPassoGeral.unidadeNegocio
          && this.objetoPassoGeral.unidadeNegocio.id) {
        idsExtensoesCarteira.push(this.objetoPassoGeral.unidadeNegocio.id);
      }
      return idsExtensoesCarteira;
    },
    montaParametrosBusca(buscaEmEtapas) {
      const parametrosVinculos = this.$refs
        .vinculosCampos
        .valoresFiltroSelecionados();
      const idDivisao = this.objetoPassoGeral.divisao.id,
        idUnidadeNegocio = this.objetoPassoGeral.unidadeNegocio.id,
        idsPeriodos = this.formulario.periodos.map((p) => p.id);

      const idHolding = (this.objetoPassoGeral.holding || {}).id;
      const idCliente = (this.objetoPassoGeral.cliente || {}).id;
      const idAcaoConfiguracaoSnapshot = this.configuracao.idConfiguracao;

      const idExtensaoCliente = (this.objetoPassoGeral.extensaoCliente || {}).id;
      const idExtensoesCarteira = this.recuperarExtensoesCarteiras();

      return {
        idDivisao,
        idsPeriodos,
        idUnidadeNegocio,
        idExtensaoCliente,
        idHolding,
        idCliente,
        buscaEmEtapas,
        idExtensoesCarteira,
        idAcaoConfiguracaoSnapshot,
        ...parametrosVinculos,
      };
    },
    montaParametrosBuscaMonetario(buscaEmEtapas) {
      const formaBonificacaoCalculo = this.objetoPassoComportamento.formaBonificacao;
      const idDivisao = this.objetoPassoGeral.divisao.id,
        idsPeriodos = this.formulario.periodos.map((p) => p.id);

      const idHolding = (this.objetoPassoGeral.holding || {}).id;
      const idCliente = (this.objetoPassoGeral.cliente || {}).id;

      const idHierarquia1 = (this.objetoPassoGeral.hierarquia1 || {}).id;
      const idHierarquia2 = (this.objetoPassoGeral.hierarquia2 || {}).id;
      const idHierarquia3 = (this.objetoPassoGeral.hierarquia3 || {}).id;
      const idsProduto = this.objetoPassoGeral.produto !== undefined ? this.objetoPassoGeral.produto.map((p) => p.id) : null;

      const vlrPercentual = this.valorDaAcao;
      const { idCalculoMonetario } = this.formulario;
      const idAcao = this.acaoEdicao.id !== undefined ? this.acaoEdicao.id : null;

      return {
        idHierarquia1,
        idHierarquia2,
        idCalculoMonetario,
        idAcao,
        idHierarquia3,
        idsProduto,
        idDivisao,
        idsPeriodos,
        idHolding,
        idCliente,
        vlrPercentual,
        buscaEmEtapas,
        formaBonificacaoCalculo,
      };
    },
    trataRespostaBusca(res, buscaEmEtapas) {
      const { removidos, contas } = res.data;

      this.contasCorrentes = [...contas];
      this.registrarContas(this.contasCorrentes);
      this.preencherContaReservaUnica();

      if (buscaEmEtapas) {
        this.$refs
          .vinculosCampos
          .desmarcaCampos(removidos);
      }
    },
    preencherContaReservaUnica() {
      if (this.reservas.length === 1) {
        this.reservas[0].filtrarEPreencherConta(this.contasCorrentes);
      }
    },
    buscarPeriodos() {
      const idDivisao = this.objetoPassoGeral.divisao.id;
      const dataInicio = this.objetoPassoGeral.dtaInicio;
      const dataFim = this.objetoPassoGeral.dtaFim;
      const { idTipoVerba } = this.formulario;

      this.planejamentoAcaoResource
        .buscarPeriodos({
          idDivisao, idTipoVerba, dataInicio, dataFim,
        })
        .then((res) => {
          this.periodosContas = [...res.data];
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    tipoVerbaSelecionado(idTipoVerba) {
      this.mascaraPercent = false;
      const rateioDivisao = this.tiposVerbas.filter((verba) => verba.id === idTipoVerba)
        .map((verbaSelecionada) => verbaSelecionada.indRateioDivisao)[0];
      this.formulario.indRateioDivisao = rateioDivisao;
      this.verbaRateioPercentual = this.objetoPassoComportamento.formaBonificacao === 'PERCENTUAL' && rateioDivisao;
      if (rateioDivisao && this.verbaRateioPercentual) {
        this.formulario.pendente = 0;
        this.valorTotal = 0;
        this.formulario.valorMonetario = 0;
        this.reservas.forEach((reserva) => {
          reserva.pendente = 0;
          reserva.valor = 0;
        });
      } else {
        this.formulario.pendente = this.objetoPassoGeral.valor;
        this.reservas[0].contas.forEach((conta) => {
          this.formulario.pendente -= conta.valor;
        });
        if (this.reservas.length > 1) {
          this.valorTotal = this.objetoPassoGeral.valor * this.reservas.length;
          this.reservas.forEach((reserva) => {
            reserva.pendente = this.objetoPassoGeral.valor;
            reserva.valor = this.objetoPassoGeral.valor;
          });
        }
      }
      this.mostrarFraseVerbaBloqueada = idTipoVerba === this.verbaSelecionada.id;
      this.setFlagMascaraPercent(idTipoVerba);
      this.formulario.periodos = [];
      this.$refs.vinculosCampos.carregaLabelsFiltrosProduto(idTipoVerba);
      this.buscarPeriodos();
    },
    getObjetoFormatado() {
      const filtrosUtilizados = this.$refs
        .vinculosCampos
        .valoresFiltroSelecionados();
      const filtrosAbertos = this.$refs
        .vinculosCampos
        .filtrosAbertos();

      const idsPeriodos = this.formulario.periodosPesquisados.map((p) => p.id);
      const { idTipoVerba } = this.formulario;
      const { valorMonetario } = this.formulario;
      const { idCalculoMonetario } = this.formulario;

      return {
        reservas: this.reservas,
        idsPeriodos,
        idTipoVerba,
        valorMonetario,
        idCalculoMonetario,
        workspace: {
          filtrosUtilizados,
          filtrosAbertos,
        },
      };
    },
    montaObjetoAcao() {
      const { passoContas } = this.acaoEdicao;
      if (passoContas) {
        this.formulario = {
          pendente: 0,
          periodos: [...passoContas.periodos],
          periodosPesquisados: [...passoContas.periodos],
          idTipoVerba: passoContas.tipoVerba.id,
        };
        this.formulario.idCalculoMonetario = this.objetoPassoGeral.idCalculoMonetario;
        this.valorPercentualAplicado = this.objetoPassoGeral.vlrPercentualAplicado;
        this.reservas = [];
        this.buscarPeriodos();
        passoContas.reservas.forEach((r) => this.reservas.push(
          new Reserva(r.dtaInicio, r.dtaFim, r.valor,
            r.ordem, r.periodoApuracao,
            `${this.$tc('label.apuracao', 1)} #${r.ordem}`,
            r.contas),
        ));

        this.montarSaldoContas();

        setTimeout(() => {
          this.reservaAlterada(this.reservas
            .map((r) => r.valorReservado)
            .reduce((a, b) => a + b, 0));
          this.atualizaPendenteReserva();
          this.atualizaCamposMarcados();
        });
      }
    },
    formataData(data) {
      return this.moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    montarSaldoContas() {
      const contas = {};
      this.reservas.flatMap((r) => r.contas)
        .forEach((c) => {
          if (this.verbaReservada || !c.valor) {
            contas[c.idContaCorrente] = c.saldo;
          } else if (contas[c.idContaCorrente]) {
            contas[c.idContaCorrente] -= c.valor;
          } else {
            contas[c.idContaCorrente] = c.saldo - c.valor;
          }
        });

      this.setContas(contas);
    },
    atualizaCamposMarcados() {
      const { passoContas } = this.acaoEdicao;
      const { filtrosAbertos, filtrosUtilizados } = passoContas;
      const utilizados = Object.keys(filtrosUtilizados);

      this.$refs
        .vinculosCampos
        .carregaLabelsFiltrosProduto(passoContas.tipoVerba.id);
      this.$refs
        .vinculosCampos
        .marcaCampos(utilizados);
      this.$refs
        .vinculosCampos
        .toggleFiltros(filtrosAbertos);
    },
    carregarTiposVerbas() {
      const { listaIdVerbaInvestimento } = this.configuracao;

      const { idTipoVerba } = this.formulario;
      const param = idTipoVerba ? { idTipoVerba } : {};

      listaIdVerbaInvestimento.forEach((idOrcamentoVerbaInvestimento) => {
        this.orcamentoTipoVerbaResource
          .buscarPorIdVerbaInvestimento({ idOrcamentoVerbaInvestimento, ...param })
          .then(({ data }) => {
            if (data) {
              this.tiposVerbas.push(data);
              this.ordenaTiposVerbas(this.tiposVerbas);
              if (this.formulario && data.id === this.formulario.idTipoVerba) {
                this.verbaSelecionada = data;
                this.setFlagMascaraPercent(this.verbaSelecionada.id);
                if (this.verbaSelecionada.indRateioDivisao) {
                  this.formulario.indRateioDivisao = this.verbaSelecionada.indRateioDivisao;
                  this.verbaRateioPercentual = this.objetoPassoComportamento.formaBonificacao === 'PERCENTUAL';
                  if (this.reservas.length > 1) {
                    this.formulario.valorMonetario = this.valorTotal / this.reservas.length;
                  } else {
                    let valorReservado = 0;
                    this.reservas.forEach((reserva) => {
                      valorReservado += reserva.valorReservado;
                    });
                    this.formulario.valorMonetario = this.objetoPassoGeral.vlrMonetario;
                    this.formulario.valorPendenteRateio = this.objetoPassoGeral.vlrMonetario - valorReservado;
                    this.formulario.pendente = this.objetoPassoGeral.vlrMonetario - valorReservado;
                  }
                }
                if (this.verbaSelecionada.indBloqueioTemp) {
                  obterCalendarioPorMnemonico({ mnemonico: 'BLOQUEIO_USO_VERBA' }, this.$resource)
                    .then((response) => {
                      this.eventoCalendario = response.body;
                      this.mostrarFraseVerbaBloqueada = this.formulario.idTipoVerba === this.verbaSelecionada.id
                              && this.eventoCalendario.dataInicio !== undefined;
                    }, (err) => {
                      this.$error(this, err);
                    });
                }
              }
              if (data.indVerbaPercentual && this.objetoPassoComportamento.formaBonificacao === 'PERCENTUAL') {
                this.formulario.idTipoVerba = data.id;
                this.tipoVerbaSelecionado(data.id);
                this.tipoSelecionado = true;
              } else if (!data.indVerbaPercentual && this.objetoPassoComportamento.formaBonificacao === 'FIXO') {
                this.formulario.idTipoVerba = data.id;
                this.tipoVerbaSelecionado(data.id);
                this.tipoSelecionado = true;
              }
            }
          });
      });
    },
    ordenaTiposVerbas(tiposVerbas) {
      tiposVerbas.sort((a, b) => {
        const fa = a.nome.toLowerCase(),
          fb = b.nome.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },
    mostrarIconeVerbaBloqueada() {
      return this.verbaSelecionada.indBloqueioTemp
      && this.mostrarFraseVerbaBloqueada
      && !this.novaAcao
      && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO' ? 'block' : undefined;
    },
    setHoldingBloqueada() {
      const { idAcao } = this.$route.params;
      const { idTipoVerba } = this.formulario;
      if (!idTipoVerba) {
        return;
      }
      this.planejamentoAcaoResource.holdingEstaBloqueada({ idAcao, idTipoVerba })
        .then((res) => {
          this.dtoHoldingEstaBloqueada = res.data;
          this.tipoDeVerbaBloqueadoParaHolding = this.dtoHoldingEstaBloqueada.bloqueado;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    setFlagMascaraPercent(idTipoVerba) {
      if (this.tiposVerbas == null || this.tiposVerbas === '') {
        return;
      }
      this.mascaraPercent = this.tiposVerbas.filter((item) => item.id === idTipoVerba)[0].indVerbaPercentual;
    },
  },
  mounted() {
    this.formulario.pendente = this.objetoPassoGeral.valor;
    this.valorDaAcao = this.objetoPassoGeral.valor;
    if (!this.novaAcao) {
      this.montaObjetoAcao();
      this.setHoldingBloqueada();
    }
    this.carregarTiposVerbas();
  },
  beforeDestroy() {
    this.registrarContas([]);
  },
};
</script>
